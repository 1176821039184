.App {
  text-align: center;
}
.dark {
  background-color: black;
  color: white;
}
.light {
  background-color: white;
  color: black;
}
